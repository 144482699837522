<!-- Modal.svelte -->
<script lang="ts">
	import { browser } from '$app/environment';
	import Icon from '$components/Icon.svelte';
	import { createEventDispatcher, onDestroy, onMount } from 'svelte';

	const dispatch = createEventDispatcher();

	export let show: boolean;
	export let title = '';

	export let isClickOutDisabled: boolean = false;
	export let isResponsive: boolean = false;
	export let hasTopClose: boolean = false;
	export let maxWidth: string = 'md:max-w-3xl';
	export let classes: string = '';
	export let isBottomOfScreen: boolean = false;
	export let useGlassyBg: boolean = false;

	let dialogRef: HTMLDialogElement;
	let contentRef: HTMLDivElement;

	function handleClickOutside(event: MouseEvent) {
		if (!isClickOutDisabled && contentRef && !contentRef.contains(event.target as Node)) {
			show = false;
		}
	}

	function closeModal() {
		show = false;
		dispatch('close');
	}

	onMount(async () => {
		if (show) {
			dialogRef.showModal();

			// Remove the focus to the first button (default html behavior)
			dialogRef.focus();
			dialogRef.blur();
		}
		if (browser) document.addEventListener('click', handleClickOutside);
	});

	onDestroy(() => {
		if (browser) document.removeEventListener('click', handleClickOutside);
	});

	$: {
		if (dialogRef) {
			if (show) {
				dialogRef.showModal();
			} else {
				dialogRef.close();
			}
		}
	}
</script>

<dialog
	bind:this={dialogRef}
	class="modal relative {isResponsive
		? 'modal-responsive-bottom'
		: 'modal-middle'} {isBottomOfScreen ? 'modal-bottom' : ''} {classes}"
>
	<div class="grid w-full justify-items-center">
		<div
			bind:this={contentRef}
			class="modal-box {useGlassyBg
				? 'glassy-bg'
				: 'gradient-bg'} w-full max-w-[100vw] overflow-hidden md:max-w-[90vw] {maxWidth}"
		>
			{#if hasTopClose}
				<button class="modal-close btn-rounded" on:click={closeModal}>
					<Icon name="cross" color="currentColor" class="rotate-45" />
				</button>
			{/if}
			{#if title}
				<div
					class="border-b border-white/30 px-5 pb-4 pl-9 font-clash text-lg font-medium leading-6 text-white"
				>
					{@html title}
				</div>
			{/if}

			<div class="modal-content px-5">
				<slot />
			</div>
		</div>
	</div>
</dialog>

<style lang="postcss">
	.modal {
		@apply fixed inset-0 z-0 grid min-h-screen w-full max-w-none justify-items-center bg-black/30 backdrop-blur;
		overscroll-behavior: contain;
		overflow-y: auto;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0.2s ease-out, opacity 0.2s ease-out;
	}

	.modal-box {
		@apply col-start-1 row-start-1 scale-90 transform border-white/[0.2] py-5 transition duration-200 ease-out;
		box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
		overscroll-behavior: contain;
	}

	.modal-bottom {
		@apply place-items-end;
	}

	.modal-bottom :where(.modal-box) {
		@apply mt-[max(48px,25%)] w-full translate-y-10 scale-100 rounded-t-[32px] border-t;
	}

	.modal-middle {
		@apply place-items-center;
	}

	.modal-middle :where(.modal-box) {
		@apply my-12 w-11/12 translate-y-0 scale-90 rounded-[32px] border;
	}

	.modal-responsive-bottom {
		@apply place-items-end sm:place-items-center;
	}

	.modal-responsive-bottom :where(.modal-box) {
		@apply mt-[max(48px,25%)] translate-y-10 scale-100 rounded-t-[32px] border-t sm:my-12 sm:w-11/12 sm:translate-y-0 sm:scale-90 sm:rounded-[32px] sm:border;
	}

	@media (max-width: 639px) {
		.modal-responsive-bottom :where(.modal-box) {
			margin-top: var(--custom-responsive-mt, max(48px, 25%));
		}
	}

	.modal-close {
		@apply absolute right-3 top-3;
	}

	.modal-content {
		@apply mt-6;
	}

	.modal[open] {
		@apply pointer-events-auto opacity-100;
		visibility: visible;
		opacity: 1;
	}

	.modal[open] .modal-box {
		@apply translate-y-0 scale-100;
	}

	.gradient-bg {
		background-size: 100% 100%;
		background: linear-gradient(310deg, #0000003f 10%, #ffffff00 100%),
			linear-gradient(168deg, #000000c7 28%, #ffffff00 100%),
			linear-gradient(315deg, #000000c7 28%, #ffffff00 100%),
			linear-gradient(101deg, #000000 0%, #9900ff 100%),
			linear-gradient(121deg, #ffffff 0%, #9900ff 100%),
			radial-gradient(52% 101.79% at 80% 30%, #707300 0%, #ff0000 100%),
			radial-gradient(100% 100% at 70% 0%, #7a3b00 0%, #1dac92 100%),
			linear-gradient(140deg, #5b0c0c 27%, #223dcb 100%),
			linear-gradient(110deg, #4e9c51 0%, #001aff 72%),
			linear-gradient(64deg, #8129f5 0%, #0038ff 100%);
	}

	.glassy-bg {
		background: linear-gradient(113deg, rgba(70, 61, 80, 0.7) 1.81%, rgba(45, 42, 54, 0.7) 98.8%);
		/* popups shadow */
		box-shadow: -8px 0px 40px 0px rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(80px);
	}

	.btn-rounded {
		@apply flex size-[42px] min-h-[42px] min-w-[42px] flex-row items-center justify-center gap-3 bg-white/10 font-clash font-semibold leading-[150%] text-white/70 backdrop-blur-[36px] transition-all;

		&:hover {
			@apply scale-105 bg-gradient-to-b from-white/20 to-white/5 text-white/80;
			box-shadow: 0px 0px 5px rgb(123, 123, 123, 0.5);
		}

		&:active {
			@apply bg-white/20 text-white;
			box-shadow: 0px 0px 10px rgb(123, 123, 123, 0.1);
		}

		border-radius: 999px;
		&::before {
			position: absolute;
			content: '';
			inset: 0;
			border-radius: 999px;
			padding: 1px; /* control the border thickness */
			background: linear-gradient(180deg, rgb(123, 123, 123, 0.5), rgb(123, 123, 123, 0));
			-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
			mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
			-webkit-mask-composite: xor;
			mask-composite: exclude;
			pointer-events: none;
		}
	}
</style>
